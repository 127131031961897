var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.reset)?_c('div',{staticClass:"w-details"},[_c('van-sticky',[_c('van-nav-bar',{staticClass:"navbar",style:(("background:" + _vm.RankColor)),attrs:{"title":"票夹详情","left-arrow":""},on:{"click-left":_vm.onClickLeft}})],1),_c('div',{staticClass:"container",attrs:{"id":"container"}},[_c('DetailHead',{ref:"detail-head"}),_c('van-row',{staticClass:"enter-methods"},[(_vm.ticketInfos.isSellOut !== '2')?_c('van-col',[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.getAdmissionModeTips( _vm.currentItem.deliveryWay, _vm.currentItem.printStatus, _vm.currentItem.ticketCode ).text)+" ")]),_c('p',{staticClass:"tips"},[(
              _vm.currentItem.deliveryWay === '03' &&
              !_vm.currentItem.validateStatus &&
              !_vm.currentItem.invalidStatus &&
              _vm.ticketInfos.productSourceEnum === 'POLY'
            )?_c('span',[_vm._v("请勿截图转发给别人,"+_vm._s(_vm.initTimes ? _vm.initTimes : 30)+"s后自动刷新 ")]):_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.getAdmissionModeTips(
                _vm.currentItem.deliveryWay,
                _vm.currentItem.printStatus,
                _vm.currentItem.ticketCode
              ).tips
            )}})])]):_vm._e(),_c('van-col',{staticClass:"steps"},[_c('span',{staticStyle:{"font-size":"0.32rem","color":"#333"}},[_vm._v(_vm._s(_vm.currentIndex))]),_c('span',{staticStyle:{"font-size":"0.34rem","color":"#999"}},[_vm._v("/")]),_c('span',{staticStyle:{"font-size":"0.28rem","color":"#999"}},[_vm._v(_vm._s(_vm.ticketItemInfos.length)+"张")])]),_c('TicketMethods',{ref:"ticket-methods",staticClass:"pick-up-wrapper",attrs:{"admission-mode":_vm.admissionMode,"show-status":_vm.showStatus,"ticket-infos":_vm.ticketInfos,"ticket-item-infos":_vm.ticketItemInfos,"product-source-enum":_vm.ticketInfos.productSourceEnum,"third-party-project":_vm.thirdPartyProject,"order-detail-id":_vm.orderDetailId},on:{"current":_vm.getCurrentItem,"validTime":_vm.validTime}}),(_vm.ticketInfos.isSellOut !== '2')?_c('van-row',{staticClass:"seat-details"},[_c('van-col',{staticClass:"seat-msg"},[_c('span',[_vm._v(" "+_vm._s(_vm.currentItem.seatDesc))])]),_c('van-col',{staticClass:"seat-real-name"},[(_vm.admissionMode)?_c('span',[_vm._v(" "+_vm._s(_vm.currentItem.moviegoersName)+" "+_vm._s(_vm.currentItem.credentialsCode)+" ")]):_vm._e(),_c('span',{staticClass:"iconfont icon-seat"}),_vm._v(" "+_vm._s(_vm.currentItem.price === 0 ? ("￥" + (_vm.currentItem.price) + ".00") : ("￥" + (_vm.currentItem.price)))+" ")]),(_vm.currentItem.ticketNo)?_c('van-col',{staticClass:"ticket-code"},[_c('span',[_vm._v(" T.N.："+_vm._s(_vm.currentItem.ticketNo)+" ")])]):_vm._e()],1):_vm._e()],1),(
        (_vm.currentItem.deliveryWay === '01' ||
          _vm.currentItem.deliveryWay === '04' ||
          _vm.currentItem.deliveryWay === '05') &&
        _vm.ticketInfos.productSourceEnum === 'MY' &&
        !_vm.currentItem.printStatus
      )?_c('van-row',{staticClass:"getTicket-address"},[(_vm.currentItem.fetchAddress)?_c('div',{staticClass:"ven-top"},[_c('van-col',{staticClass:"venue-header"},[_c('van-image',{staticClass:"loca-img",attrs:{"fit":_vm.imageFit,"src":require('@/assets/images/Project/icon-addr@2x.png')}}),_vm._v(" 取票地址 ")],1),_c('p',{staticStyle:{"font-size":"0.24rem","color":"#999999"}},[_vm._v(" "+_vm._s(_vm.currentItem.fetchAddress)+" ")])],1):_vm._e()]):(
        (_vm.currentItem.deliveryWay === '01' ||
          _vm.currentItem.deliveryWay === '04' ||
          _vm.currentItem.deliveryWay === '05') &&
        _vm.ticketInfos.productSourceEnum !== 'MH' &&
        !_vm.currentItem.printStatus
      )?_c('van-row',{staticClass:"getTicket-address"},[(
          _vm.ticketInfos.ticketPlaceAddresses &&
          _vm.ticketInfos.ticketPlaceAddresses[0]
        )?_c('div',{staticClass:"ven-top"},[_c('van-col',{staticClass:"venue-header"},[_c('van-image',{staticClass:"loca-img",attrs:{"fit":_vm.imageFit,"src":require('@/assets/images/Project/icon-addr@2x.png')}}),_vm._v(" 取票地址 ")],1),_c('p',{staticStyle:{"font-size":"0.24rem","color":"#999999"}},[_vm._v(" "+_vm._s(_vm.ticketInfos.ticketPlaceAddresses && _vm.ticketInfos.ticketPlaceAddresses[0])+" ")])],1):_vm._e()]):_vm._e(),(
        _vm.currentItem.deliveryWay === '05' &&
        _vm.ticketInfos.productSourceEnum == 'MY'
      )?_c('van-row',{staticClass:"getTicket-person"},[(
          _vm.currentItem.localeContactPersons &&
          _vm.currentItem.localeContactPersons[0]
        )?_c('div',{staticClass:"ven-top"},[_c('van-col',{staticClass:"venue-header"},[_c('van-image',{staticClass:"loca-img",attrs:{"fit":_vm.imageFit,"src":require('@/assets/images/Project/icon-per2x.png')}}),_vm._v(" 取票联系人 ")],1),_vm._l((_vm.currentItem.localeContactPersons),function(item,index){return _c('div',{key:index},[_c('van-row',{staticClass:"item",attrs:{"type":"flex","align":"center","justify":"start"}},[_c('van-field',{attrs:{"label-width":"1.3rem","label":"姓名","readonly":true},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1),_c('van-row',{staticClass:"item",attrs:{"type":"flex","align":"center","justify":"start"}},[_c('van-field',{attrs:{"label-width":"1.3rem","label":"手机号","readonly":true},model:{value:(item.mobiles),callback:function ($$v) {_vm.$set(item, "mobiles", $$v)},expression:"item.mobiles"}})],1)],1)})],2):_vm._e()]):_vm._e(),_c('van-row',{staticClass:"venue-address"},[_c('div',{staticClass:"ven-top",style:({
          'border-radius':
            _vm.TheaterModule.theaterId === _vm.currentItem.orderTheaterId
              ? '0 0 0 0'
              : '0 0 0.15rem 0.15rem',
        })},[_c('van-col',{staticClass:"venue-header"},[_c('van-image',{staticClass:"loca-img",attrs:{"fit":_vm.imageFit,"src":require('@/assets/images/Project/icon-addr@2x.png')}}),_vm._v(" 场馆地址 ")],1),_c('router-link',{staticStyle:{"width":"100%"},attrs:{"to":("/map?name=" + (_vm.ticketInfos.placeCname) + "&address=" + (_vm.ticketInfos.placeCaddress))}},[_c('van-row',{staticClass:"venue-content"},[_c('van-col',{staticClass:"vunue-name",attrs:{"span":"22"}},[_c('h3',[_vm._v(_vm._s(_vm.ticketInfos.placeCname))]),_c('p',[_vm._v(_vm._s(_vm.ticketInfos.placeCaddress))])]),_c('van-col',{staticClass:"show-map",attrs:{"span":"2"}},[_c('van-icon',{attrs:{"name":"arrow"}})],1)],1)],1)],1),(_vm.TheaterModule.theaterId === _vm.currentItem.orderTheaterId)?_c('van-image',{staticClass:"m-line",attrs:{"fit":_vm.imageFit,"src":require('../../../../assets/images/Wallet/w4.png')}}):_vm._e()],1),(_vm.TheaterModule.theaterId === _vm.currentItem.orderTheaterId)?_c('van-row',{staticClass:"order-details"},[_c('van-col',{staticClass:"left",attrs:{"span":"22"}},[_c('h3',[_vm._v("订单详情")]),_c('p',[_vm._v("查看订单信息、配送方式、申请开票等")]),(
            _vm.ticketInfos.productSourceEnum &&
            _vm.ticketInfos.productSourceEnum != 'POLY'
          )?_c('p',[_vm._v(" 本场演出票品由"+_vm._s(_vm.ticketInfos.productSourceEnum == "DM" ? "大麦网" : _vm.ticketInfos.productSourceEnum == "MY" ? "猫眼演出" : _vm.ticketInfos.productSourceEnum == "MH" ? "开心麻花" : "")+"提供 ")]):_vm._e()]),_c('van-col',{staticClass:"right",attrs:{"span":"2"}},[_c('van-icon',{attrs:{"name":"arrow"},on:{"click":_vm.goOrderDetails}})],1)],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }