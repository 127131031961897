









































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  NavBar,
  Cell,
  CellGroup,
  Image,
  Field,
  Row,
  Col,
  Icon,
  CountDown,
  Sticky,
} from "vant";
import StatusBar from "@/components/StatusBar.vue";
import TicketMethods from "./Components/getTicketMethods.vue";
import DetailHead from "./Components/DetailHead.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [CountDown.name]: CountDown,
    [Sticky.name]: Sticky,
    StatusBar,
    DetailHead,
    TicketMethods,
  },
})
export default class Details extends Mixins(Mixin) {
  public id = "";
  public orderId = "";
  public ticketInfos: order.TicketFolderDetailDto = {};
  public admissionMode = false; // 入场方式（是否实名）
  public showStatus = false; // 场次是否结束
  public ticketItemInfos: Array<order.TicketItemRepository> = []; // 票信息
  public title: string | (string | null)[] = "";
  public type: string | (string | null)[] = "";
  public content = "";
  public thirdPartyProject = false;
  public orderDetailId = "";
  public reset = true;
  public currentItem: order.TicketItemRepository = {};
  public currentIndex = 1;
  scrollTop = "";
  public countdown = {
    num: 30,
    status: false,
  };
  public timer: any;
  public initTimes = 0;
  isFixed = false;
  get RankColor(): string {
    return this.topsH > 10 ? "#ffc80d" : "transparent";
  }
  topsH = 0;

  mounted(): void {
    this.reset = false;
    this.id = this.$route.params.id;
    this.orderId = this.$route.query.orderId
      ? String(this.$route.query.orderId)
      : "";
    this.scrollTop = this.$route.params.scrollTop;
    this.getTicketDetail();
    this.$nextTick(() => {
      this.reset = true;
    });
    this.wxpay();

    window.addEventListener(
      "scroll",
      () => {
        this.$nextTick(() => {
          this.topsH = document.getElementById("container")!.scrollTop;
        });
      },
      true
    );
  }

  validTime(time: number): void {
    this.initTimes = time;
    this.countdown.num = time;
  }
  // 禁用项目详情页面分享
  wxpay(): void {
    let a = window as any;
    a.WeixinJSBridge && a.WeixinJSBridge.call("hideOptionMenu");
  }
  getCurrentItem(index: number): void {
    clearInterval(this.timer);
    this.countdown.num = 30;
    this.countdown.status = false;
    this.currentIndex = index;
    this.currentItem = this.ticketItemInfos[index - 1];
    if (
      this.currentItem.deliveryWay === "03" &&
      !this.currentItem.invalidStatus &&
      !this.currentItem.validateStatus &&
      this.ticketInfos.productSourceEnum === "POLY"
    ) {
      this.countDown();
    }
  }
  goOrderDetails(): void {
    //从订单详情过来，直接返回上一页
    // if (this.$route.query.formOrder) {
    //   this.$router.go(-1);
    // } else {
    this.$router.push(
      `/performance/per-order/${this.currentItem.orderId}/${this.ticketInfos.productSourceEnum}?fromWallet=1`
    );
    // }
  }
  getTicketDetail(): void {
    this.$api.orderApi.ticketFolder.getTicketFolderDetailById(
      this.id,
      ({ data }) => {
        this.ticketInfos = data;
        this.admissionMode = data.admissionMode!;
        this.showStatus = data.showStatus!;
        this.ticketItemInfos = data.ticketItemInfos!;
        this.thirdPartyProject = data.thirdPartyProject!;
        /* 如果从订单详情页来 定位到展示当前订单的票夹信息；如果从列表进入，定位到第一张 */
        if (data.ticketItemInfos && this.orderId) {
          let idx = data.ticketItemInfos.findIndex((item) => {
            return item.orderId === this.orderId;
          });
          if (idx >= 0) {
            this.$nextTick(() => {
              let ticketMethods = this.$refs["ticket-methods"] as TicketMethods;
              if (ticketMethods) {
                ticketMethods.swiperSlideToSome(idx);
              }
            });
            this.currentIndex = idx + 1;
            this.orderDetailId = data.ticketItemInfos![idx].orderDetailId!;
            this.currentItem = data.ticketItemInfos![idx];
          } else {
            this.orderDetailId = data.ticketItemInfos![0].orderDetailId!;
            this.currentItem = data.ticketItemInfos![0];
            this.$nextTick(() => {
              let ticketMethods = this.$refs["ticket-methods"] as TicketMethods;
              if (ticketMethods) {
                ticketMethods.swiperSlideToSome(0);
              }
            });
          }
        } else {
          this.orderDetailId = data.ticketItemInfos![0].orderDetailId!;
          this.currentItem = data.ticketItemInfos![0];
          this.$nextTick(() => {
            let ticketMethods = this.$refs["ticket-methods"] as TicketMethods;
            if (ticketMethods) {
              ticketMethods.swiperSlideToSome(0);
            }
          });
        }

        if (
          this.currentItem.deliveryWay === "03" &&
          !this.currentItem.invalidStatus &&
          !this.currentItem.validateStatus &&
          this.ticketInfos.productSourceEnum === "POLY"
        ) {
          this.countDown();
        }
        this.$nextTick(() => {
          let detailHead = this.$refs["detail-head"] as DetailHead;
          if (detailHead) {
            detailHead.getInfo(this.ticketInfos);
          }
        });
      }
    );
  }
  // 入场方式
  getAdmissionModeTips(
    deliveryWay: string | undefined,
    printStatus: boolean | undefined,
    ticketCode: string | undefined
  ): {
    text: string;
    tips: string;
  } {
    /**入场方式
     * 1、电子票
     * 非实名制显示“请凭电子票入场”； 实名制显示“请凭电子票及有效证件入场”
     * 2、现场取票
     * 票未打印状态时，显示：“请取票，取票码：xxxxxx”
     * 票已打印状态时：
     *      非实名制显示： “请凭纸质票入场”，“此页面不作为入场凭证”；
     *      实名制显示：“请凭纸质票及有效证件入场”，“此页面不作为入场凭证”。
     * 3、快递取票 订单支付成功即为已打印状态
     * 票已打印状态时：
     *    非实名制显示： “请凭纸质票入场”，“此页面不作为入场凭证”；
     *    实名制显示：“请凭纸质票及有效证件入场”，“此页面不作为入场凭证”。
     *
     *  */
    let obj = {
      text: "",
      tips: "",
    };
    if (deliveryWay === "03") {
      // 电子票
      if (this.admissionMode) {
        obj.text = "请凭电子票及有效证件入场";
      } else {
        obj.text = "请凭电子票入场";
        obj.tips = "请勿将截图转发给别人";
      }
    } else if (deliveryWay === "02") {
      // 快递取票
      if (this.admissionMode) {
        obj.text = "请凭纸质票及有效证件入场";
        obj.tips = "此页面不作为入场凭证";
      } else {
        obj.text = "请凭纸质票入场";
        obj.tips = "此页面不作为入场凭证";
      }
    } else if (deliveryWay === "01") {
      // 自助取票/现场取票
      if (this.ticketInfos.productSourceEnum === "MH") {
        obj.text = "请取票";
        obj.tips = ticketCode ? `取票码：${ticketCode}` : "";
      } else if (this.ticketInfos.productSourceEnum === "POLY") {
        if (printStatus) {
          if (this.admissionMode) {
            obj.text = "请凭纸质票及有效证件入场";
            obj.tips = "此页面不作为入场凭证";
          } else {
            obj.text = "请凭纸质票入场";
            obj.tips = "此页面不作为入场凭证";
          }
        } else {
          obj.text = "请取票";
          obj.tips = ticketCode ? `取票码：${ticketCode}` : "";
        }
      } else if (
        this.ticketInfos.productSourceEnum === "DM" ||
        this.ticketInfos.productSourceEnum === "MY"
      ) {
        obj.text = "请取票";
        obj.tips =
          this.currentItem.isTicketVoided &&
          this.ticketInfos.productSourceEnum === "MY" &&
          ticketCode
            ? `取票码：<del>${ticketCode}</del>`
            : ticketCode
            ? `取票码：${ticketCode}`
            : "";
      }
    } else if (deliveryWay === "06") {
      obj.text = "请凭有效证件入场";
    } else if (deliveryWay === "04") {
      obj.text = "请取票";
      obj.tips =
        this.currentItem.isTicketVoided &&
        this.ticketInfos.productSourceEnum === "MY" &&
        ticketCode
          ? `取票码：<del>${ticketCode}</del>`
          : ticketCode
          ? `取票码：${ticketCode}`
          : "";
    } else if (deliveryWay === "05") {
      //  临场派票 猫眼项目 未取票
      if (this.ticketInfos.productSourceEnum === "MY" && !printStatus) {
        obj.text = "请取票";
        obj.tips =
          this.currentItem.isTicketVoided && ticketCode
            ? `取票码：<del>${ticketCode}</del>`
            : ticketCode
            ? `取票码：${ticketCode}`
            : "";
      } else {
        if (this.admissionMode) {
          // 实名制
          obj.text = "请凭有效证件入场";
        } else {
          obj.text = "请凭纸质票入场";
        }
      }
    }
    return obj;
  }
  // 验证码发送倒计时
  countDown(): void {
    this.countdown.status = true;
    this.countdown.num--;
    this.timer = setTimeout(() => {
      this.countDown();
    }, 1000);
    if (this.countdown.num === 0) {
      clearInterval(this.timer);
      this.countDownFinish();
      this.countdown.status = false;
      this.countdown.num = 30;
      return;
    }
  }
  countDownFinish(): void {
    // 倒计时结束刷新二维码
    let ticketMethods = this.$refs["ticket-methods"] as TicketMethods;
    if (ticketMethods) {
      ticketMethods.getQRCode(this.currentItem.orderDetailId!);
    }
    if (
      this.currentItem.deliveryWay === "03" &&
      !this.currentItem.invalidStatus &&
      !this.currentItem.validateStatus &&
      this.ticketInfos.productSourceEnum === "POLY"
    ) {
      this.countDown();
    }
  }
  onClickLeft(): void {
    clearInterval(this.timer);
    if (this.AppSource == "harmonyOS") {
      this.jsBridge("back", "", () => {
        this.$router.go(-1);
      });
    } else {
      this.$router.go(-1);
    }
  }
  beforeDestroy(): void {
    clearInterval(this.timer);
    window.removeEventListener(
      "scroll",
      () => {
        this.$nextTick(() => {
          this.topsH = document.getElementById("container")!.scrollTop;
        });
      },
      true
    );
  }
}
