import { render, staticRenderFns } from "./DetailHead.vue?vue&type=template&id=3db33cc6&scoped=true&"
import script from "./DetailHead.vue?vue&type=script&lang=ts&"
export * from "./DetailHead.vue?vue&type=script&lang=ts&"
import style0 from "./DetailHead.vue?vue&type=style&index=0&id=3db33cc6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3db33cc6",
  null
  
)

export default component.exports