







































import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Cell, CellGroup, Image, Dialog, Row } from "vant";
import StatusBar from "@/components/StatusBar.vue";
import WalletItem from "./WalletItem.vue";
import { Route, NavigationGuardNext } from "vue-router";
@Component({
  name: "wallet",
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Row.name]: Row,
    [Dialog.Component.name]: Dialog.Component,
    StatusBar,
    WalletItem,
  },
})
export default class Wallet extends Mixins(Mixin) {
  public show = false;
  public tickets: Array<order.TicketFolderItem> = [];
  public historyTickets: Array<order.TicketFolderItem> = [];
  public showWhite = false;
  public scrollTop = 0;
  isBackFrom = "";

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    next((vm) => {
      let isBackFrom = (vm as Wallet).isBackFrom;
      // if(fromPath === )
      if (isBackFrom) {
        (vm as Wallet).isBackFrom = "";
      }
      if (!isBackFrom || isBackFrom != from.fullPath) {
        /**
         * isBackFrom 为空说明不是从 Home 出去的或者不需要保持位置的
         * isBackFrom != from.fullPath 表示是从 Home 出去的，但是不是从出去的第一个页面回来的
         */
        (vm as Wallet).scrollTop = 0;
        (vm as Wallet).refreshPage(); // 刷新分页数据
      }
    });
  }

  mounted() {
    if (
      this.AppSource == "harmonyOS" &&
      this.ExternalAccessModule.isExternalAccessReady
    ) {
      this.refreshPage();
    }
  }

  activated(): void {
    this.scrollTo(String(this.scrollTop));
  }
  scrollTo(val: string): void {
    this.$nextTick(() => {
      let item = document.getElementById("scroll");
      if (item) {
        item.scrollTop = Number(val);
      }
    });
  }
  refreshPage(): void {
    this.$nextTick(() => {
      let item = document.getElementById("scroll");
      if (item) {
        item.scrollTop = 0;
      }
    });
    this.getTickets();
  }
  onClickRight(): void {
    console.log(555);
  }
  onClickLeft(): void {
    if (this.AppSource == "harmonyOS") {
      this.jsBridge("back", "", () => {
        this.$router.go(-1);
      });
    } else {
      this.$router.go(-1);
    }
  }
  getTickets(): void {
    this.$api.orderApi.ticketFolder.getTicketFolder(({ data }) => {
      this.tickets = data.tickets!;
      this.historyTickets = data.historyTickets!;
    });
  }
  goWalletDetail(item: order.TicketFolderItem): void {
    let e = document.getElementById("scroll");

    if (e) this.scrollTop = e.scrollTop;
    let path = `/wallet/details/${item.id}`;
    this.isBackFrom = path;
    this.$router.push(path);
  }
}
