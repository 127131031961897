




import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import WalletDetail from "@/views/Mine/MoreServices/Wallet/Details.vue";

@Component({
  components: {
    WalletDetail,
  },
})
export default class ExternalWalletDetailPage extends Mixins(Mixin) {}
